import PropTypes from 'prop-types'
import React from 'react'
import placelogo from '../images/place_logo_128.png'
import shardwarelogo from '../images/infinity-clearbackground-small.png'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        {/* about */}
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <p>
            Busitect Digital is an Australian-based business solutions
            consultancy group focused on delivering transformative change with
            meaningful impact through the application of technology.
          </p>
          <p>
            Comprised of a core group of experts with a deep pool of experience
            across a wide range of disciplines, we prefer a human-first and
            process-centric approach to defining projects with technology being
            the catalyst to enable the ensuing vision.
          </p>
          <p>
            With strong connections into the software development, data and
            managed service ecosystem both locally and internationally we are
            able to scale to take on projects of any size.
          </p>
          {close}
        </article>
        {/* shardware */}
        <article
          id="shardware"
          className={`${this.props.article === 'shardware' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <img
            src={shardwarelogo}
            alt="Shardware Logo"
            style={{
              width: '20%',
              maxWidth: '200px',
              marginLeft: '10px',
              marginRight: '20px',
              marginBottom: '10px',
              float: 'right',
            }}
          />
          <h2 className="major">Things</h2>

          <p>
            Shardware is an industrial "internet of things" initiative, which
            aims to leverage the capabilities of modern cloud to unlock the
            power of IoT for every business.
          </p>
          <p>
            With out-of-the-box device management, security, analytics and easy
            integration with downstream reporting systems the Shardware platform
            allows even smaller companies to derive the same benefits from the
            technology as the industry heavyweights, without the software
            development or IT maintenance costs associated with build-your-own.
          </p>
          <p>Please contact us for more information.</p>
          {close}
        </article>

        {/* placeearth */}
        <article
          id="placeearth"
          className={`${this.props.article === 'placeearth' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <img
            src={placelogo}
            alt="Place.Earth Logo"
            style={{
              width: '15%',
              maxWidth: '78px',
              marginLeft: '10px',
              marginBottom: '10px',
              float: 'right',
            }}
          />
          <h2 className="major">Place</h2>

          <p>
            Place.Earth is a platform project currently in prototyping phase,
            focused on solving the problem of location-centric communication in
            a fragmented (i.e. non-centralised), communications environment.
          </p>
          <p>
            The platform will be particularly suited to population-scale crisis
            communication scenarios.
          </p>
          <p>
            For more information, please visit the pre-launch concept page at{' '}
            <a href="https://place.earth/" target="_blank" rel="noreferrer">
              Place.Earth
            </a>
          </p>
          {close}
        </article>

        {/* contact */}
        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form
            method="get"
            action="https://us-central1-place-earth.cloudfunctions.net/sendMail"
          >
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>

          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main

/*
<ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
*/
